.title {
  text-align: center;
}

h3.title {
  margin-top: 10px;
}

.table {
  overflow-x: auto;

  :global {
    td[colspan="4"] > .ant-table-wrapper {
      margin-bottom: 20px;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  .table {
    :global {
      .ant-table-expand-icon-col {
        width: 30px;
      }

      .ant-table-cell {
        font-size: 12px;
        padding: 0 4px;

      }
    }
  }

  .openButton {
    display: none;
  }

  .actionButtons {
    flex-direction: column;

    :global {
      .ant-alert {
        font-size: 10px;
      }
    }

    button {
      font-size: 12px;
    }
  }
}
